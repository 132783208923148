@import "./variables.module.scss";
.order {
  background-color: #eef1f8;
}
.bodyMain{
  margin-top: 4rem;
  @include large_device{
    margin-top: 13rem;
  }
}
.errorMsg {
  font-size: 13px;
  color: #e50f36;
  margin-bottom: 0;
  margin-top: 2px;
  line-height: 20px;
}
.orderForm {
  &.orderPage {
    padding-bottom: 120px;
    & ~ .orderServices {
      position: relative;
      &:before {
        content: "";
        background-image: url($bg-base-path + "/papercuteffact.svg");
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        height: 80px;
        background-repeat: no-repeat;
        background-size: cover;
        top: -80px;
        z-index: 0;
      }
      .service {
        .name {
          margin-top: 30px;
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 20px;
          line-height: 23px;
          color: $theme-color;
        }
        .desc {
          font-size: 15px;
          line-height: 23px;
          color: $desc-font;
          padding: 0 10px;
        }
      }
    }
  }
}
.orderServices {
  padding: 50px 0 0;
  background: $white-color;
  .serviceRow {
    border-bottom: 1px solid #ddd;
    .service {
      position: relative;
      z-index: 1;
      margin-bottom: 30px;
      .image {
        height: 60px;
      }
    }
  }
}

.orderPage {
  padding-top: 30px;
  padding-bottom: 30px;
}
.sectionPart {
  background-color: $white-color;
  padding: 30px;
  border-radius: 10px;
  -webkit-box-shadow: 5px 8px 30px rgba(61, 90, 112, 0.17);
  box-shadow: 5px 8px 30px rgba(61, 90, 112, 0.17);
  @include up_large {
    padding: 20px;
  }
  .paymentOpts {
    margin-top: 32px;
    @include medium_device {
      margin-top: 20px;
    }
  }
  &.leftSide {
    .buttons {
      margin-top: 40px;
    }
    .sectionTitle {
      margin-bottom: 40px;
    }
    .list {
      column-gap: 15px;
    }
  }
  .bottomPart {
    padding-top: 10px;
    span {
      font-weight: 700;
      font-size: 24px;
      @include up_large {
        font-size: 20px;
      }
    }
    .totalPrice {
      color: $theme-custom-color;
      @include large_device {
        font-size: 16px;
      }
    }
    .price {
      color: #13b675;
      @include large_device {
        font-weight: bold;
        font-size: 21px;
        line-height: 28px;
      }
    }
  }
  &.rightSide {
    padding: 20px 30px;
    position: sticky;
    top: 100px;
    @include up_large {
      top: 111px;
    }
    @include medium_device {
      margin-top: 20px;
    }
    .sectionTitle {
      word-wrap: break-word;
    }
  }
  &.orderTable {
    background-color: transparent;
    padding: 0;
    position: relative;
  }
  .sectionTitle {
    font-size: 20px;
    position: relative;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .currencyMenu {
    .dropdown-menu {
      min-width: 150px;
      .dropdown-item {
        span {
          font-size: 14px;
        }
      }
    }
    .dropdown-toggle {
      width: 150px;
      background-color: #f2f6f8;
      border: none;
      text-align: left;
      color: $theme-custom-color;
      font-weight: 400;
      font-size: 14px;
      border-radius: $border-radius;
      @include mobile_device {
        width: 100%;
      }
      &[aria-expanded="true"] {
        &:after {
          -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
        }
      }
      &:after {
        content: "";
        background-image: url($bg-base-path + "/dropdown-toggle.svg");
        width: 12px;
        height: 6px;
        position: absolute;
        background-repeat: no-repeat;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-size: contain;
        right: 15px;
        border: none;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
      &::before {
        content: none;
      }
    }
  }
  .formGroup {
    margin-bottom: 20px;
    position: relative;
    @include up_large {
      margin-bottom: 16px;
    }
    .btn-group {
      & > * {
        flex: 1;
      }
    }
    .deadlineLabel {
      font-size: 13px;
      margin-top: 5px;
      float: right;
      opacity: 0.6;
    }
    .timezone-picker {
      width: 100%;
      .timezone-picker-textfield {
        position: relative;
        &::before {
          content: "";
          background-image: url($bg-base-path + "/dropdown-toggle.svg");
          width: 12px;
          height: 6px;
          position: absolute;
          background-repeat: no-repeat;
          top: 18px;
          background-size: contain;
          right: 15px;
          border: none;
          -webkit-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
        }
      }
      &.timezone-picker-open {
        .timezone-picker-textfield {
          &::before {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }
      input {
        border: none;
        font-weight: 500;
      }
      .timezone-picker-list {
        font-size: 14px;
        color: $theme-custom-color;
        border-radius: $border-radius;
        margin-top: 4px;
        &::-webkit-scrollbar {
          width: 5px;
          background: #f2f6f8;
          border-radius: $border-radius;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #c4c6c7;
          border-radius: $border-radius;
        }
        li {
          font-size: 15px;
          border-bottom: 1px solid #f0f4f6;
          color: $theme-custom-color;
          padding: 10px 10px;
          font-weight: 400;
          &:hover {
            background: #f2f6f8;
          }
        }
      }
    }
    .dropzoneInput {
      background-color: #f2f6f8;
      height: 80px;
      padding: 20px 10px;
      border: 1px dashed #c4c4c4;
      border-radius: $border-radius;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      @include up_large {
        height: 60px;
      }
      .label {
        font-size: 14px;
      }
      input {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      .browseBtn {
        border: 1px solid #c4c4c4;
        background: transparent;
        border-radius: $border-radius;
        padding: 7px 20px;
        margin-right: 10px;
      }
      span {
        color: #888888;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .fileListing {
      margin-top: 12px;
      li {
        column-gap: 5px;
        margin-bottom: 5px;
        p {
          font-size: 14px;
          line-height: 20px;
          text-align: left;
          margin: 0;
          font-weight: 400;
          word-break: break-all;
          padding-left: 0;
          @include up_large {
            font-size: 13px;
          }
        }
        button {
          background-color: transparent;
          border: none;
          svg {
            fill: $black-color;
          }
        }
      }
    }
    .file-upload {
      padding: 5px 15px;
      font-size: 14px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
      color: $black-color;
      margin-bottom: 0;
      text-align: right;
      @include up_large {
        font-size: 14px;
        line-height: 22px;
      }
      @include medium_device {
        margin-bottom: 5px;
        text-align: left;
      }
      &.infoText {
        font-weight: normal;
        font-size: 14px;
        margin-left: 40px;

        @include up_large {
          margin-left: 34px;
        }

        @include medium_device {
          margin-left: 0;
        }
      }
      &.errorText {
        font-weight: normal;
        font-size: 14px;
        color: red;;
      }
      span {
        margin-left: 5px;
        svg {
          cursor: pointer;
          position: relative;
          width: 16px;
          height: 16px;
          display: inline-block;
          z-index: 1;
          overflow: hidden;
        }
      }
      &.errorText {
        color: #e50f36;
        font-size: 13px;
        margin-top: 4px;
        font-weight: 400;
        text-align: left;
        line-height: normal;
      }
    }
    .dropdown {
      .dropdown-toggle {
        background-color: #f2f6f8;
        border: none;
        text-align: left;
        color: $theme-custom-color;
        font-weight: 400;
        font-size: 14px;
        border-radius: $border-radius;
        padding: 10px 10px;
        line-height: 20px;
        position: relative;
        &:after {
          content: "";
          background-image: url($bg-base-path + "/dropdown-toggle.svg");
          width: 12px;
          height: 6px;
          position: absolute;
          background-repeat: no-repeat;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          background-size: contain;
          right: 15px;
          border: none;
          -webkit-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
        }
        &[aria-expanded="true"] {
          &::after {
            -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
    .list {
      input {
        display: none;
        width: 100%;
        position: inherit;
        label {
          @include up_large {
            padding: 6px 8px;
            line-height: 20px;
            font-size: 13px;
          }
        }
        &:checked {
          & + label {
            background: $secondary-color;
          }
        }
      }
      .btn {
        background: #f2f6f8;
        padding: 8px 10px;
        border-radius: $border-radius;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        color: $black-color;
        border: none;
        line-height: 24px;
        flex: 1;
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: center;
        @include up_large {
          padding: 8px;
          line-height: 18px;
        }
        &::before {
          content: none;
        }
        + .btn {
          margin-left: 10px;
        }
      }
      li {
        flex: 1;
        background: #f2f6f8;
        padding: 10px;
        line-height: 20px;
        border-radius: $border-radius;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        &.active {
          background-color: $secondary-color;
        }
        + li {
          margin-left: 10px;
        }
      }
    }
    .speakerNotesRow{
      font-size: 0.8rem;
      align-items: center;
      svg{
        cursor: pointer;
      }
    }
    .numberOfPages {
      background: #f2f6f8;
      border-radius: $border-radius;
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 150px;
      @include up_large {
        width: 130px;
      }
      @include extra_large {
        margin-right: 0;
        margin-left: auto;
      }
      .counter {
        display: inline-block;
        text-align: center;
        font-size: 20px;
        cursor: pointer;
        width: 50px;
      }
      .count {
        display: inline-block;
        text-align: center;
        color: $theme-custom-color;
        font-weight: 500;
        font-size: 14px;
        width: 50px;
        @include up_large {
          font-size: 13px;
        }
        input {
          display: inline-block;
          width: 100%;
          text-align: center;
          border: 0;
        }
      }
    }
    textarea {
      resize: none;
      font-size: 14px;
      padding: 10px;
    }
    [class^="selectInput__control"] {
      -webkit-box-shadow: none;
      box-shadow: none;
      border: none;
      background: #f2f6f8;
      position: relative;
      border-radius: $border-radius;
      min-height: 40px;
      &::after {
        content: "";
        background-image: url($bg-base-path + "/dropdown-toggle.svg");
        width: 12px;
        height: 6px;
        position: absolute;
        background-repeat: no-repeat;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-size: contain;
        right: 15px;
        border: none;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
      &[class*="selectInput__control--menu-is-open"] {
        &:after {
          -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
        }
      }
      [class*="selectInput__value-container"] {
        ~ div {
          margin: 0;
          padding: 0;
        }
      }
      [class*="selectInput__single-value"] {
        font-size: 14px;
        font-weight: 500;
        color: $theme-custom-color;
      }
      [class*="selectInput__input"] {
        input {
          height: 28px;
        }
      }
    }
    [class*="selectInput__placeholder"] {
      color: $black-color;
      font-size: 14px;
      font-weight: 500;
    }
    .selectInputDeadline {
      [class*="selectInput__menu"] {
        top: auto;
        bottom: 35px;
      }
    }
    [class*="selectInput__menu"] {
      -webkit-box-shadow: none;
      margin-top: 4px;
      border: 1px solid #f2f6f8;
      z-index: 2;
      box-shadow: 0 7px 20px 0px rgba(38, 56, 77, 0.06);
      [class*="selectInput__menu-list"] {
        padding: 0;
        &::-webkit-scrollbar {
          width: 5px;
          background: #f2f6f8;
          border-radius: $border-radius;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #c4c6c7;
          border-radius: $border-radius;
        }
        [class*="selectInput__option"] {
          font-size: 15px;
          border-bottom: 1px solid #f0f4f6;
          color: $theme-custom-color;
          padding: 10px 10px;
          font-weight: 400;
          background-color: $white-color;
          &:hover,
          &[class*="selectInput__option--is-focused"] {
            background-color: #f2f6f8;
          }
          &[class*="selectInput__option--is-selected"] {
            background-color: #f2f6f8;
            color: $theme-custom-color;
            font-weight: 500;
          }
        }
      }
    }
    textarea.formControl {
      height: 110px;
      border-radius: 10px !important;
      @include up_large {
        height: auto;
      }
    }
    .formControl {
      background: #f2f6f8;
      height: 40px;
      font-size: 14px;
      border-radius: $border-radius !important;
      color: $theme-custom-color;
      outline: none;
      &:focus {
        background: #f2f6f8;
      }
    }
  }
  .rightSection {
    .statusTime {
      padding-top: 10px;
      border-top: 1px solid #f0f4f6;
      span {
        color: #888888;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-transform: capitalize;
      }
    }
    .summaryDetail {
      margin-bottom: 20px;
      border-bottom: 1px solid #f0f4f6;
      padding-bottom: 20px;
      line-height: normal;
      @include large_device {
        margin-bottom: 15px;
        padding-bottom: 15px;
      }
      .sectionTitle {
        line-height: normal;
      }
      .viewOrderDetailsLink {
        padding: 4px 18px;
        width: auto;
        line-height: 20px;
        @media screen and (max-width: 1366px) {
          padding: 4px 8px;
        }
      }
    }
    .writersOptions {
      display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #f0f4f6;
      .options {
        margin-bottom: 20px;
        width: calc(100% / 2 - 5px);
        margin-right: 5px;
        @include up_large {
          margin-bottom: 10px;
        }
        .text1 {
          color: $desc-font;
          font-weight: 400;
          font-size: 14px;
          display: block;
          line-height: 32px;
          margin-bottom: 2px;
        }
        .text2 {
          font-size: 16px;
          color: $black-color;
          display: inline-block;
          line-height: 28px;
          @include up_large {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .couponCode {
    padding: 15px 0;
    border-top: 1px solid #f0f4f6;
    border-bottom: 1px solid #f0f4f6;
    @include extra_large {
      padding: 10px 0;
    }
    .couponActionBtn {
      svg {
        cursor: pointer;
      }
    }
    .couponOffer {
      cursor: pointer;
      img {
        height: fit-content;
      }
      span {
        @include extra_large {
          font-size: 14px;
        }
      }
    }
    label {
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 10px;
      @include extra_large {
        margin-bottom: 0;
      }
    }
    .formControl {
      background: #f2f6f8;
      border: 1px dashed #c4c4c4;
      border-radius: $border-radius !important;
      height: 44px;
      color: #888888;
      font-size: 14px;
      margin-right: 10px;
      @include up_large {
        height: 40px;
      }
    }
    .applyBtn {
      border-radius: $border-radius !important;
      width: auto;
      line-height: normal;
      padding: 0 30px;
    }
    .success {
      color: #13b675;
    }
    .error {
      color: #dd2525;
    }
    .btn {
      @include up_large {
        padding: 8px 15px;
      }
    }
  }
}
.orderLogin {
  .sidebar {
    background: #0d1940;
    width: 260px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 99;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    .navbarBrand {
      padding: 10px;
      line-height: 40px;
      color: $white-color;
      background: $theme-color;
      border-radius: 0 0 20px 20px;
      font-size: 25px;
      font-weight: 500;
      width: 100%;
    }
    .profile {
      text-align: center;
      padding: 40px 20px;
      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50px;
      }
      .name {
        margin-bottom: 0;
        color: $white-color;
        margin-top: 20px;
        font-size: 18px;
        font-weight: 500;
        line-height: 15px;
        word-break: break-word;
        text-transform: capitalize;
      }
    }
    .menuLinks {
      background: $theme-color;
      padding: 40px 30px 20px 0;
      border-top-left-radius: 20px;
      border-top-right-radius: 90px;
      border-bottom-right-radius: 20px;
      width: 100%;
      height: 100%;
      ul {
        max-height: calc(100vh - 320px);
        overflow: auto;
        &::-webkit-scrollbar {
          width: 0;
        }
        li {
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            color: $white-color;
            font-size: 15px;
            text-transform: capitalize;
            padding: 8px 8px 8px 20px;
            // display: block;
            display: flex;
            align-items: center;
            border-radius: 0 5px 5px 0;
            position: relative;
            cursor: pointer;
            &.active {
              background: #2a3a6a;
              &:before {
                content: "";
                position: absolute;
                width: 2px;
                height: 100%;
                background-color: $secondary-color;
                left: 0;
                top: 0;
                bottom: 0;
              }
            }
            [class^="bedge"] {
              background-color: #0d1940;
              padding: 6px 10px;
              margin-left: 5px;
              border-radius: 50px;
              font-size: 14px;
            }
            img {
              margin-right: 10px;
              width: 20px;
            }
          }
        }
      }
      .sidebarlink {
        position: absolute;
        bottom: 20px;
        left: 15px;
        .downloadLink {
          background-color: #0d1940;
          padding: 8px 15px;
          border-radius: 50px;
          color: $white-color;
          display: inline-flex;
          text-align: center;
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
  .orderingDetails {
    width: calc(100% - 260px);
    margin-left: auto;
    height: 100vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
      background: #f2f6f8;
      border-radius: $border-radius;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c4c6c7;
      border-radius: $border-radius;
    }
    .ordersTab {
      padding: 30px;
      @include large_device {
        padding: 16px;
      }
      .sectionPart {
        &.rightSide {
          top: 20px;
          margin-top: 50px;
          @include large_device {
            margin-top: 20px;
          }
          .orderSummary {
            .bottomCalculate {
              .price {
                .status {
                  font-size: 18px;
                  &.inProgress {
                    color: #ff6b00;
                  }
                  &.completed {
                    color: #07c451;
                  }
                }
              }
            }
            .statusTime {
              [class*="timer-div"] {
                margin-top: 18px;
                text-align: center;
                [class*="square-timer"] {
                  display: grid;
                  width: 60px;
                  & + [class*="square-timer"] {
                    margin-left: 20px;
                  }
                  [class*="timer-text"] {
                    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.07);
                    height: 60px;
                    background-image: linear-gradient(
                      0deg,
                      $white-color 50%,
                      #f7f7f7 50%
                    );
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 60px;
                    color: $theme-custom-color;
                  }
                  [class*="timer-subText"] {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 32px;
                    text-align: center;
                    color: $theme-custom-color;
                    margin-top: 5px;
                  }
                }
              }
            }
          }
        }
      }
      .orderTab {
        [class*="tab-content"] {
          .loader {
            background: $white-color;
            padding: 20px;
            max-height: calc(100% - 0px);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 0 10px 10px;
            @media screen and (max-width: 991px) {
              background-color: transparent;
              padding: 0;
            }
          }
        }
      }
    }
    .navTabs {
      .navItem {
        [class*="order-status-tab"] {
          border: none;
          border-radius: 10px 10px 0px 0px;
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          color: $desc-font;
          padding: 10px 20px;
          cursor: pointer;
          text-transform: capitalize;
          transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
          margin-bottom: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0);
          @include up_large {
            font-size: 18px;
            padding: 8px 15px;
          }
          @include extra_large {
            font-size: 16px;
          }
          &[class*="active"] {
            background-color: $white-color;
            // border-bottom-color: #fbd501;
            // filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.1));
          }
          .bedgeCount {
            width: 20px;
            height: 20px;
            display: inline-block;
            background: $secondary-color;
            line-height: 20px;
            border-radius: 50px;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            color: $theme-custom-color;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
      }
    }
    .loader {
      background: $white-color;
      padding: 20px;
      max-height: calc(100% - 0px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 0 10px 10px;
      @media screen and (max-width: 991px) {
        background-color: transparent;
        padding: 0;
      }
    }
    .title {
      font-size: 24px;
      font-weight: 700;
      text-transform: capitalize;
      margin-bottom: 20px;
      line-height: 40px;
      color: $theme-custom-color;
    }
    .checkboxLabel {
      position: relative;
      text-align: right;
      span {
        img {
          cursor: pointer;
        }
      }
      input[type="checkbox"] {
        display: block;
        cursor: pointer;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        -webkit-appearance: none;
        appearance: none;
        &:checked {
          & + label {
            &:after {
              content: "";
              position: absolute;
              width: 22px;
              height: 22px;
              background: $secondary-color;
              border-radius: $border-radius;
              right: 4px;
              top: 4px;
              z-index: 0;
            }
          }
        }
      }
      label {
        padding-right: 40px;
        position: relative;
        font-weight: 500;
        line-height: 32px;
        &:before {
          content: "";
          -webkit-appearance: none;
          appearance: none;
          background-color: $white-color;
          border: 1px solid #e7e7e7;
          width: 30px;
          height: 30px;
          display: inline-block;
          position: absolute;
          vertical-align: middle;
          cursor: pointer;
          border-radius: $border-radius;
          right: 0;
          z-index: 0;
          top: 0;
        }
      }
    }
    .confirmPayBtn {
      background-color: #07c451;
      color: $theme-custom-color;
      font-weight: 700;
      &::before {
        border-color: #07c451;
      }
    }
    .topLink {
      padding: 30px;
      position: static;
      right: 0;
      left: 0;
      top: 0;
      z-index: 3;
      background: #eef1f8;
      @include up_large {
        padding: 20px;
      }
      .navTabs {
        padding: 10px;
        background: $white-color;
        border-radius: 10px;
        flex-wrap: nowrap;
        @include up_large {
          padding: 8px;
        }
        .navItem {
          flex: 1;
          @include up_large {
            margin: 0;
          }
          .orderStepsTab {
            padding: 12px 20px;
            border-radius: 10px;
            border: none;
            cursor: pointer;
            @include up_large {
              padding: 8px;
            }
            &.disabled {
              opacity: 0.5;
              pointer-events: none;
            }
            &.done {
              .icon {
                background: #eef1f8;
                @include up_large {
                  width: 40px;
                  height: 40px;
                  line-height: 40px;
                }
                svg {
                  display: none;
                }
                &::before {
                  content: "";
                  position: absolute;
                  background-image: url($bg-base-path + "/order/check.svg");
                  background-repeat: no-repeat;
                  background-size: 15px;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: 16px;
                  height: 12px;
                  display: inline-block;
                  z-index: 1;
                }
                &::after {
                  content: "";
                  position: absolute;
                  width: 30px;
                  height: 30px;
                  background-color: #07c451;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  border-radius: 50px;
                  -webkit-transition: all 0.4s ease-in-out;
                  transition: all 0.4s ease-in-out;
                  @include up_large {
                    width: 25px;
                    height: 25px;
                  }
                }
              }
            }
            &.active {
              background-color: $theme-color;
              .icon {
                background: #07c451;
                svg {
                  stroke: $white-color;
                }
              }
              .details {
                .text {
                  color: $white-color;
                }
                .stepName {
                  color: $white-color;
                }
              }
            }
            .icon {
              width: 50px;
              height: 50px;
              background: #eef1f8;
              text-align: center;
              line-height: 50px;
              border-radius: 50px;
              position: relative;
              -webkit-transition: all 0.4s ease-in-out;
              transition: all 0.4s ease-in-out;
              @include up_large {
                width: 40px;
                height: 40px;
                line-height: 40px;
              }
              &:after {
                content: "";
                width: 0;
                height: 0;
                -webkit-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
                @include up_large {
                  width: 25px;
                  height: 25px;
                }
              }
              svg {
                stroke: $theme-custom-color;
                @include up_large {
                  width: 18px;
                  height: 18px;
                }
              }
            }
            .details {
              margin-left: 16px;
              width: calc(100% - 70px);
              .text {
                color: #888888;
                font-size: 14px;
                text-transform: capitalize;
                margin-bottom: 5px;
              }
              .stepName {
                font-size: 16px;
                color: $theme-custom-color;
                margin-bottom: 0;
                font-weight: 500;
                white-space: nowrap;
                @include up_large {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
    .sectionPart {
      &.leftSide {
        .paymentCard {
          padding: 40px 0;
          border-top: 1px solid #f0f4f6;
          border-bottom: 1px solid #f0f4f6;
          margin: 20px 0;
          @include large_device {
            padding: 20px 0;
          }
          cursor: pointer;
          .orderFormLabel {
            & + .orderFormLabel {
              margin-top: 40px;
            }
            .paymentCard {
              svg {
                cursor: pointer;
              }
            }
          }
          .inputGroup {
            padding-left: 40px;
            margin: 10px 0 40px;
            align-items: normal;
            width: auto;
            flex-wrap: nowrap;
            @include mobile_device {
              margin: 10px 0;
              padding-left: 0;
              flex-wrap: wrap;
            }
            .cardInput {
              background: #edf2f7 !important;
              color: $theme-custom-color;
              font-size: 14px;
              font-weight: 500;
              border-radius: $border-radius !important;
              flex: inherit;
              height: 40px;
              &.cardNumber {
                width: 160px !important;
                margin-right: 10px;
              }
              &.cvvInput {
                width: 80px !important;
                height: 40px;
              }
            }
            .error-text {
              position: absolute;
              bottom: -25px;
            }
            .editBtn {
              font-size: 16px;
              color: $theme-color;
              font-weight: 500;
              padding: 0 26px;
              width: auto;
              height: 40px;
              @include extra_small {
                margin-top: 10px;
                width: 100%;
                margin-left: 0 !important;
              }
              &::before {
                content: none;
              }
            }
          }
          .radioLabel {
            input[type="radio"] {
              display: block;
              cursor: pointer;
              width: 22px;
              height: 22px;
              position: relative;
              left: 0;
              -webkit-appearance: none;
              appearance: none;
              z-index: 10;
              &:checked {
                & + label {
                  &::before {
                    background-color: $secondary-color;
                    border-color: $secondary-color;
                  }
                  &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 15px;
                    -webkit-transform: translateY(-50%) rotate(45deg);
                    transform: translateY(-50%) rotate(45deg);
                    left: -12px;
                    width: 6px;
                    height: 12px;
                    border: solid $theme-custom-color;
                    border-width: 0 2px 2px 0;
                  }
                }
              }
            }
            label {
              display: inline-block;
              position: relative;
              padding-left: 15px;
              font-size: 16px;
              font-weight: 500;
              line-height: 32px;
              &:before {
                content: "";
                -webkit-appearance: none;
                appearance: none;
                border: 1px solid #888888;
                width: 22px;
                height: 22px;
                display: inline-block;
                position: absolute;
                vertical-align: middle;
                cursor: pointer;
                border-radius: 50px;
                left: -20px;
                z-index: 0;
                top: 6px;
              }
            }
          }
        }
        .border-leftSide {
          border-left: 1px dashed #dde6ef;
        }
        &::-webkit-scrollbar {
          width: 5px;
          background: #f2f6f8;
          border-radius: $border-radius;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #c4c6c7;
          border-radius: $border-radius;
        }
        .bottompart {
          .balanceDetails {
            .text {
              color: $theme-custom-color;
              font-weight: 700;
            }
          }
          .options {
            margin-top: 40px;
          }
        }
        .balanceDetails {
          .text {
            font-size: 20px;
            color: #888888;
            font-weight: 500;
            margin-right: 20px;
          }
          .price {
            font-size: 20px;
            color: $theme-custom-color;
            font-weight: 700;
          }
          .inputGroup {
            width: 250px;
            margin-left: auto;
            border-radius: $border-radius;
            overflow: hidden;
            .redeemBtn {
              border-top-left-radius: 0 !important;
              border-bottom-left-radius: 0 !important;
              width: auto;
              -webkit-box-shadow: none;
              box-shadow: none;
              margin-left: 0;
              font-size: 16px;
              padding: 7px 30px;
              z-index: 0;
            }
          }
          .formControl {
            background: #edf2f7;
            font-size: 14px;
            color: #888888;
            border: none;
          }
          .redeemBtn {
            &::before {
              content: none;
            }
          }
        }
        .calculatePart {
          &.total {
            border-top: 1px solid #f0f4f6;
            .price {
              span {
                font-weight: 500;
              }
            }
          }
        }
        .content {
          .more {
            color: $secondary-color;
            cursor: pointer;
          }
        }
        .paymentOpt {
          .formGroup {
            .inputGroup {
              .formControl {
                width: 100%;
              }
            }
          }
          .totalPrice {
            border: 1px solid #f0f4f6;
            padding: 20px;
            .label {
              font-weight: 500;
              font-size: 24px;
              line-height: 23px;
            }
            .price {
              font-weight: bold;
              font-size: 24px;
              line-height: 23px;
              color: #07c451;
            }
          }
          .top {
            margin: 30px 0;
          }
        }
        .securePay {
          font-weight: bold;
          font-size: 18px;
          line-height: 36px;
          text-transform: capitalize;
          color: #151515;
          margin: 20px 0 5px;
          .desc {
            color: $desc-font;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
          }
        }
        .options {
          .ways {
            background: $white-color;
            border-radius: 10px;
            border: 1px solid #f0f4f6;
            padding: 20px;
            margin-top: 10px;
            .details {
              margin-left: 15px;
              width: calc(100% - 75px);
              .label {
                color: #12245a;
                font-weight: bold;
                font-size: 16px;
                line-height: 23px;
                margin-bottom: 4px;
              }
              .desc {
                font-weight: normal;
                font-size: 13px;
                line-height: 23px;
                color: $desc-font;
              }
            }
          }
        }
      }
      &.rightSide {
        position: sticky;
        @include up_large {
          padding: 20px;
        }
        &::-webkit-scrollbar {
          width: 5px;
          background: #f2f6f8;
          border-radius: $border-radius;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #c4c6c7;
          border-radius: $border-radius;
        }
        .StripeElement {
          width: 115px;
        }
        .bottomPart {
          padding-top: 10px;
          [class*="form-check-inline"] {
            margin-right: 10px;
            input {
              cursor: pointer;
              background-color: $white-color;
              &:focus {
                outline: none;
                box-shadow: none;
              }
              &:checked,
              &:hover {
                background-color: $secondary-color;
                border-color: $secondary-color;
              }
            }
            & ~ label {
              font-size: 12px;
              text-align: left;
            }
          }
          @include large_device {
            background: #ffffff;
            box-shadow: 2px -12px 28px rgb(0 0 0 / 8%);
            bottom: 0;
            position: fixed;
            left: 0;
            right: 0;
            padding: 12px 16px;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: space-between;
            & > * {
              flex: 1;
            }
            [class^="mt-lg-2"] {
              text-align: right;
            }
          }
          [class*="mb-3"] {
            @include large_device {
              margin-bottom: 0 !important;
              flex-direction: column;
              justify-content: flex-start !important;
              align-items: flex-start !important;
            }
            .totalPrice {
              @include large_device {
                font-size: 16px;
                font-weight: 400;
              }
            }
            .price {
              @include large_device {
                font-weight: bold;
                font-size: 21px;
                line-height: 28px;
              }
            }
          }
          .step4 {
            margin-top: 10px;
          }
          span {
            font-weight: 700;
            font-size: 24px;
            @include extra_large_device {
              font-size: 18px;
            }
          }
          .btn {
            @include large_device {
              padding: 11px 24px;
              z-index: 3;
              width: auto !important;
            }
          }
          .totalPrice {
            color: $theme-custom-color;
          }
          .price {
            color: #13b675;
          }
          .savedPrice {
            color: $desc-font;
            font-weight: 500;
            text-transform: lowercase;

            &::first-letter {
              text-transform: uppercase;
            }

            .savedAmount {
              font-weight: 700;
              color: $theme-custom-color;
              text-transform: uppercase;
            }
          }
        }
        .paymentOpts {
          margin-top: 32px;
        }
        .writersOptions {
          border-bottom: 1px solid #f0f4f6;
        }
        .orderSummary {
          .sectionTitle {
            &::before {
              margin: 0;
            }
          }
          .bottomCalculate {
            .price {
              span {
                &:last-child {
                  font-size: 20px;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
    > [class*="tab-content"] {
      padding: 0 30px 30px;
      background: #eef1f8;
      @include up_large {
        padding: 0 20px 20px;
      }
      &[class*="orderInvoice"] {
        height: 100vh;
        padding: 30px;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        @include medium_device {
          height: auto;
          padding: 30px 0;
        }
        &::-webkit-scrollbar {
          width: 5px;
          background: #f2f6f8;
          border-radius: $border-radius;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #c4c6c7;
          border-radius: $border-radius;
        }
        .calculatePart {
          padding-bottom: 0;
        }
      }
      .sectionPart {
        border-radius: 10px;
        -webkit-box-shadow: 5px 8px 30px rgba(61, 90, 112, 0.17);
        box-shadow: 5px 8px 30px rgba(61, 90, 112, 0.17);
        &.leftSide {
          border-radius: 10px;
          padding: 20px 30px;
          &.bundleSection {
            margin-bottom: 40px;
            padding-top: 15px;
            .sectionTitle {
              text-align: center;
              border-bottom: 1px solid #f0f4f6;
              padding-bottom: 10px;
              margin-bottom: 20px;
            }
            .bundleWrapper {
              gap: 15px;
              border-radius: 8px 8px 0 0;
              .bundle {
                width: calc(50% - 20px);
                border: 1px solid #edf2f7;
                border-radius: 8px;
                cursor: pointer;
                background-color: transparent;
                padding: 0;
                text-align: left;
                &:hover {
                  border-color: $secondary-color;
                }
                &.active {
                  background: $secondary-color;
                  border-color: $secondary-color;

                  .bundleHeadingSection {
                    background-color: $secondary-color;
                  }
                }
              }
              .bundleHeadingSection {
                background: url($bg-base-path + "bundle-bg.png") no-repeat
                  scroll 0 0 #edf2f7;
                background-size: cover;
                padding: 15px 15px 15px 0px;
                border-radius: 8px 8px 0 0;

                .headingText {
                  padding: 12px 20px 12px 12px;
                  border-radius: 0 50px 50px 0;
                  background-color: $theme-color;
                  color: $white-color;
                  font-size: 18px;
                  line-height: 24px;
                  margin-right: 40px;
                  font-weight: 500;
                }

                .saveTextBlock {
                  .savingHeading {
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 32px;
                    margin-bottom: 5px;
                    color: $desc-font;
                  }

                  .savingAmount {
                    font-size: 28px;
                    font-weight: 700;
                    line-height: 32px;
                    color: $theme-custom-color;
                    margin-bottom: 0;
                  }
                }
              }
              .bundleBenefitsBlock {
                padding: 20px;
                li {
                  color: $theme-color;
                  margin-bottom: 10px;
                  font-size: 16px;
                  line-height: 28px;
                  &:last-child {
                    margin-bottom: 0px;
                  }
                  svg {
                    margin-right: 10px;
                  }
                }
              }
            }
          }
          &.orderComplete {
            .sectionTitle {
              border-bottom: 1px solid #f0f4f6;
              padding-bottom: 10px;
              margin-bottom: 30px;
            }
            .bottomCalculate {
              padding-bottom: 0;
              padding-top: 20px;
              margin-top: 20px;
              .bottomPart {
                .totalPrice,
                .price {
                  font-size: 24px;
                  font-weight: 700;
                }
                .price {
                  color: #13b675;
                }
              }
            }
          }
        }
      }
      .orderSummaryDiv {
        @include medium_device {
          margin-bottom: 30px;
        }
        .orderingDiv {
          > img {
            width: 280px;
          }
          .title {
            font-size: 24px;
            margin: 30px 0 20px;
            line-height: 32px;
            @include medium_device {
              margin-top: 0;
            }
          }
          .desc {
            margin-bottom: 30px;
          }
        }
      }
    }
    .draftOrderTab {
      margin-top: 80px;
      [class*="tab-content"] {
        .img {
          img {
            width: 400px;
          }
        }
      }
      .navTabs {
        .navItem {
          margin-right: 120px;
          position: relative;
          &:last-child {
            margin-right: 0;
            &::before {
              content: none;
            }
          }
          &::before {
            content: "";
            position: absolute;
            background-image: url($bg-base-path + "/order/line.svg");
            width: 60px;
            height: 10px;
            right: -90px;
            background-repeat: no-repeat;
            top: 50%;
            transform: translateY(-50%);
          }
          .stepsTab {
            border: none;
            padding: 0;
            cursor: pointer;
            &[class*="active"] {
              background-color: transparent;
              .tabMedia {
                .icon {
                  background: #fbd501;
                  color: $theme-custom-color;
                }
              }
            }
            .tabMedia {
              .icon {
                width: 50px;
                height: 50px;
                background: $white-color;
                border-radius: $border-radius;
                text-align: center;
                color: #888888;
                line-height: 50px;
                margin-right: 20px;
                font-weight: 500;
                font-size: 18px;
                &.done {
                  background: $theme-color;
                }
              }
              .details {
                .text {
                  color: #888888;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                  margin-bottom: 5px;
                }
                .stepName {
                  margin-bottom: 0;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: $theme-custom-color;
                }
              }
            }
          }
        }
      }
    }
  }

  .orderDetailsBtn {
    & > * {
      padding: 8px 0;
      width: 140px;
    }
  }
}
.react-bootstrap-table {
  .table {
    td {
      .topic-column {
        min-width: 120px;
      }
      .status-column {
        min-width: 160px;
      }
      .transNo-column {
        min-width: 150px;
      }
      .transDetail-column {
        min-width: 200px;
      }
    }
  }
}
.react-bootstrap-table-pagination {
  .react-bs-table-sizePerPage-dropdown {
    .dropdown-toggle {
      background: $white-color;
      border-radius: 8px;
      border: none;
      color: #222222;
      margin-right: 10px;
      padding: 11px 13px;
      &::after,
      &::before {
        content: none;
      }
    }
  }
}
.footer {
  &:before {
    z-index: -1;
  }
}
.couponDetailModal {
  max-width: inherit;
  @include desktop_up {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  [class*="-dialog"] {
    max-width: initial;
    justify-content: center;
    [class$="-content"] {
      background: $body-color;
      padding: 50px 50px 30px;
      width: auto;
      max-width: 90vw;
      overflow: hidden;
      @include mobile_device {
        padding: 20px;
      }
      .sectionTitle {
        margin-bottom: 30px;
        font-size: 24px;
        line-height: 52px;
        font-weight: 700;
        @include medium_device {
          margin-bottom: 10px;
        }
      }
      .order {
        column-gap: 30px;
        @include extra_large {
          column-gap: 20px;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
      .close {
        position: absolute;
        top: 50px;
        right: 50px;
        background: $white-color;
        width: 40px;
        height: 40px;
        border-radius: 3px;
        text-align: center;
        line-height: 37px;
        cursor: pointer;
        @include mobile_device {
          top: 0;
          right: 0;
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
      }
      .couponWrap {
        padding: 24px;
        -webkit-box-shadow: 5px 8px 30px rgba(61, 90, 112, 0.17);
        box-shadow: 5px 8px 30px rgba(61, 90, 112, 0.17);
        border-radius: 8px;
        position: relative;
        margin-bottom: 20px;
        width: 400px;
        @include medium_device {
          width: 100%;
        }
        @media screen and (min-width: 768px) and (max-width: 1199px) {
          width: 290px;
        }
        .topPick {
          background-image: url($bg-base-path + "/order/topPicklbl.svg");
          width: 105px;
          height: 105px;
          position: absolute;
          right: -10px;
          background-repeat: no-repeat;
          top: -10px;
        }
        .saveDis {
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          color: #151515;
          margin-bottom: 20px;
          display: block;
          position: relative;
          padding-left: 15px;
          z-index: 1;
          &::before {
            content: "";
            position: absolute;
            background-image: url($bg-base-path + "/order/couponCodeBg.svg");
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            height: 30px;
            left: 0;
            top: -5px;
            z-index: -1;
          }
        }
        .disText {
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 8px;
          @include mobile_device {
            font-size: 16px;
          }
          .discount {
            color: #4267d7;
          }
        }
        .inputGroup {
          .formControl {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-right: none;
          }
          .applyBtn {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }
}
.fileDetails {
  background-color: #f2f6f8;
  padding: 10px;
  border-radius: 6px;
  display: inline-block;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
  &.writerFiles,
  &.completeOrder {
    .icon {
      width: 100%;
      margin-bottom: 5px;
      @media screen and (min-width: 1700px) {
        width: calc(100% - 210px);
      }
    }
  }
  &.completeOrder {
    align-items: center;
    @include up_large {
      flex-wrap: wrap;
    }
  }
  &.writerFiles {
    .icon {
      margin-bottom: 0px;
      margin-right: 10px;
      width: auto;
    }
  }
  .downloadWriterFile {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      line-height: 1;
    }
  }
  .icon {
    width: calc(100% - 90px);
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .removeFile {
    cursor: pointer;
    display: inline-flex;
  }
  .details {
    margin-left: 10px;
    margin-right: 10px;
    line-height: normal;
    width: calc(100% - 40px);
    .name {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      color: $theme-custom-color;
      word-break: break-all;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .dateCompleted{
      text-overflow: unset;
      overflow: unset;
    }
    .text {
      color: #888888;
      font-size: 12px;
      line-height: 20px;
    }
  }
  .dateCompletedDetails{
    margin-left: unset;
  }
  .userName,
  .view {
    font-size: 16px;
    color: $theme-custom-color;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    line-height: normal;
    margin-right: 10px;
    align-items: center;
  }
  .userName {
    margin-right: 0;
    cursor: default;
  }
  .downloadFile {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $theme-color;
    margin-right: 0;
    @include up_large {
      flex-wrap: wrap;
    }
  }
}
.dateCompletedFileDetails{
  padding-bottom: 6px;
}
.checkOrderDetails {
  .leftSide {
    border-radius: 0 0 10px 10px;
    -webkit-box-shadow: 5px 8px 30px rgba(61, 90, 112, 0.17);
    box-shadow: 5px 8px 30px rgba(61, 90, 112, 0.17);
    @include large_device {
      padding: 20px;
    }
    .orderFileDetails {
      .myFiles {
        .fileDetails {
          .icon {
            width: calc(100% - 120px);
          }
          .details {
            width: calc(100% - 40px);
            .name {
              font-size: 16px;
              line-height: 24px;
              color: $theme-custom-color;
              white-space: nowrap;
              display: block;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
      button {
        span {
          display: inline-block;
          vertical-align: text-top;
        }
      }
      & + .orderFileDetails {
        margin-top: 30px;
        @include large_device {
          margin-top: 0;
        }
      }
    }
  }
  .tabTitle {
    padding-bottom: 20px;
    border-bottom: 1px solid #dde6ef;
    margin-bottom: 20px;
    @include large_device {
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 40px;
      text-transform: capitalize;
      margin-bottom: 0;
      @include large_device {
        font-size: 22px;
      }
    }
    .fileInput {
      border: none;
      background: transparent;
      padding: 0;
      .fileInputInput {
        width: 120px;
        position: relative;
        cursor: pointer;
        border: 1px solid $theme-color;
        background: transparent;
        padding: 7px 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: normal;
        border-radius: $border-radius;
        &::-webkit-file-upload-button {
          visibility: hidden;
        }
        &::before {
          content: "";
          background-image: url($bg-base-path + "/order/upload.svg");
          width: 12px;
          height: 12px;
          display: inline-block;
          background-size: 12px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 20px;
        }
        &::after {
          content: "Upload";
          color: $theme-custom-color;
          display: inline-block;
          position: absolute;
          left: 40px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .navTabs {
    .navItem {
      .orderDetailsTab {
        border: none;
        border-radius: 10px 10px 0px 0px;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #888888;
        padding: 10px 20px;
        cursor: pointer;
        text-transform: capitalize;
        @include large_device {
          line-height: 24px;
        }
        &[class*="active"] {
          color: $theme-custom-color;
          font-weight: 500;
          background-color: $white-color;
        }
      }
    }
  }
  .orderDetailsBtn {
    float: right;
    @include large_device {
      padding: 0;
    }
    button {
      border: 1px solid $theme-color;
      background: transparent;
      padding: 7px 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: $border-radius;
      @include large_device {
        padding: 0;
        border: none;
        span {
          display: none;
        }
      }
      svg {
        position: relative;
        top: -1px;
      }
      span {
        display: inline-block;
        vertical-align: text-top;
      }
    }
  }
}
.reviewModal {
  [class*="modal-content"] {
    padding: 0 !important;
    background: $body-color;
    .submitReview {
      padding: 32px;
      @media only screen and (max-width: 767px) {
        padding: 20px;
      }
      .bg {
        background: $white-color;
        border-radius: 10px;
        padding: 20px;
        @media only screen and (max-width: 767px) {
          padding: 15px;
        }
      }
      .icons {
        margin-bottom: 16px;
        span {
          margin: 0 5px;
          @media only screen and (max-width: 767px) {
            font-size: 32px !important;
            img {
              height: 32px !important;
              width: 32px !important;
            }
          }
          @media only screen and (max-width: 575px) {
            font-size: 24px !important;
            margin: 0 3px;
            img {
              height: 24px !important;
              width: 24px !important;
            }
          }
        }
        svg {
          height: 42px;
          width: 42px;
          margin: 0 5px;
          @media only screen and (max-width: 767px) {
            height: 32px;
            width: 32px;
          }
          @media only screen and (max-width: 575px) {
            height: 24px;
            width: 24px;
            margin: 0 3px;
          }
          path {
            stroke: $secondary-color;
            fill: $secondary-color;
          }
        }
      }
      textarea {
        border: 1px solid #c4c4c4;
        border-radius: 10px !important;
        padding: 16px;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 20px;
      }
      .error-text {
        color: #e50f36;
        font-size: 13px;
        margin-top: 4px;
        font-weight: 400;
        text-align: left;
        line-height: normal;
      }
      .success-text {
        color: #13b675;
        font-size: 25px;
        font-weight: 700;
        text-align: center;
        line-height: normal;
      }
    }
  }
  .title {
    font-size: 24px;
    line-height: 1;
    background: $white-color;
    padding: 28px 32px;
    border-radius: 10px 10px 0 0;
    margin: 0;
    @media only screen and (max-width: 767px) {
      font-size: 22px;
      padding: 22px 20px;
    }
    .closeModal {
      padding: 0;
      border: none;
      background: none;
    }
  }
}
.orderStatus {
  .back {
    cursor: pointer;
    span {
      color: $theme-custom-color;
      font-weight: 500;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .paymentViewTab {
    .calculatePart {
      border-bottom: none;
    }
    .sectionPart {
      border-radius: 0 0 10px 10px;
      .writerDetail {
        font-weight: 500;
        span {
          &:last-child {
            color: $secondary-color;
          }
        }
      }
      .price {
        .desc {
          line-height: 28px;
          font-size: 16px;
          color: $theme-custom-color;
          word-break: break-all;
        }
      }
      .content {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        padding-bottom: 20px;
        border-bottom: 1px solid #f0f4f6;
        margin-bottom: 20px;
      }
      .reviewPopup {
        p {
          margin-bottom: 2px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }
        span {
          cursor: pointer;
          margin: 0 2px;
          transition: 0.2s;
          svg {
            margin: 0 2px;
            transition: 0.2s;
            &:hover {
              path {
                fill: $secondary-color;
                stroke: $secondary-color;
              }
            }
          }
        }
      }
      .innerTitle {
        font-size: 16px;
        line-height: 28px;
        color: $theme-custom-color;
        margin-bottom: 12px;
      }
      .desc {
        margin-bottom: 0;
        color: #888888;
        font-size: 16px;
        line-height: 28px;
      }
      .bottomCalculate {
        border-bottom: none;
        padding: 20px 0 0;
      }
      .bottomPart {
        border-top: none;
        span {
          font-weight: 700;
          font-size: 24px;
        }
        .totalPrice {
          color: $theme-custom-color;
        }
        .price {
          color: #13b675;
          font-size: 24px;
        }
      }
    }
  }
}
.calculatePart {
  padding: 15px 0;
  font-size: 18px;
  border-bottom: 1px solid #f0f4f6;
  @include up_large {
    padding: 10px 0;
    font-size: 16px;
  }
  &.bottomCalculate {
    padding: 10px 0;
    border-top: 1px solid #f0f4f6;
    border-bottom: none;
  }
  &[class*="pt-0"] {
    border-bottom: none;
    & ~ .bottomPart {
      border-top: 1px solid #f0f4f6;
    }
  }
  .price {
    line-height: 28px;
    font-size: 16px;
    color: $theme-custom-color;
    word-break: break-all;
    .returnRedeem {
      border-bottom: 1px solid;
      color: #07c451;
      font-weight: 400;
      cursor: pointer;
    }
    &:not(:first-child) {
      margin-top: 10px;
    }
    & > * {
      text-overflow: ellipsis;
      // overflow: hidden;
      overflow: visible;
      // white-space: nowrap;
      white-space: normal;
      &:first-child {
        font-weight: 400;
        // width: 50%;
      }
      &:last-child {
        text-align: right;
        font-weight: 500;
        // width: 50%;
      }
      .desc {
        color: #151515;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
.orderDetailModal {
  [class*="modal-dialog"] {
    [class*="modal-content"] {
      padding: 0;
      overflow: hidden;
      .modalTitle {
        padding: 16px 32px;
        background: $white-color;
        font-weight: bold;
        font-size: 24px;
        line-height: 40px;
        color: $theme-custom-color;
      }
      .checkOrderDetails {
        padding: 30px;
        background: $body-color;
        @include large_device {
          padding: 16px;
        }
      }
    }
  }
}
.deleteOrderModal {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.7;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
}
@media screen and (max-width: 1366px) {
  .order {
    .sectionPart {
      .sectionTitle {
        font-size: 18px;
      }
      .calculatePart {
        .price {
          span {
            &:first-child,
            &:last-child {
              font-size: 14px;
            }
          }
          &:not(:first-child) {
            margin-top: 5px;
          }
        }
      }
    }
    .orderLogin {
      .fileDetails {
        .details {
          .name {
            font-size: 14px;
          }
        }
      }
      .orderingDetails {
        & > [class*="tab-content"] {
          .sectionPart {
            &.leftSide {
              padding: 20px;

              &.bundleSection {
                .bundleWrapper {
                  .bundleHeadingSection {
                    .headingText {
                      margin-right: 15px;
                      font-size: 16px;
                    }

                    .saveTextBlock {
                      .savingHeading {
                        font-size: 18px;
                        line-height: 25px;
                        margin-bottom: 0;
                      }

                      .savingAmount {
                        font-size: 24px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .draftOrderTab {
          margin-top: 40px;
          .navTabs {
            .navItem {
              margin-right: 30px;
              &::before {
                content: none;
              }
            }
          }
        }
        .navTabs {
          .navItem {
            // .nav-link {
            .order-steps-tab {
              font-size: 18px;
              line-height: 26px;
              padding: 10px 18px;
            }
          }
        }
      }
      .checkOrderDetails {
        .tabTitle {
          .title {
            font-size: 20px;
          }
        }
        .orderDetailsBtn {
          button {
            padding: 4px 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .order {
    .orderLogin {
      .checkOrderDetails {
        .tabTitle {
          .title {
            font-size: 20px;
          }
        }
      }
      .fileDetails {
        margin-bottom: 10px;
        .userName,
        .view {
          font-size: 14px;
        }
      }
      .orderingDetails {
        [class*="tab-content"] {
          &.orderInvoice {
            height: auto;
            padding: 15px !important;
            .orderSummaryDiv {
              margin-bottom: 15px;
            }
          }
        }

        > [class*="tab-content"] {
          .sectionPart {
            &.leftSide {
              &.bundleSection {
                .bundleWrapper {
                  .bundleHeadingSection {
                    .headingText {
                      margin-right: 15px;
                      font-size: 14px;
                      line-height: 19px;
                    }
                    .saveTextBlock {
                      .savingHeading {
                        font-size: 16px;
                        line-height: 20px;
                      }

                      .savingAmount {
                        font-size: 20px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .topLink {
          .navTabs {
            .navItem {
              .orderStepsTab {
                .details {
                  margin-left: 10px;
                  width: calc(100% - 50px);
                }
              }
            }
          }
        }

        .draftOrderTab {
          .navTabs {
            .navItem {
              margin-right: 30px;
              .stepsTab {
                .tabMedia {
                  .icon {
                    width: 40px;
                    height: 40px;
                    line-height: 42px;
                    margin-right: 10px;
                    font-size: 16px;
                  }
                  .details {
                    .stepName {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
        .sectionPart {
          .bottomPart {
            span {
              font-size: 18px;
            }
          }
          .calculatePart {
            .price {
              span {
                &:first-child,
                &:last-child {
                  font-size: 14px;
                  line-height: 24px;
                }
                &.price {
                  font-size: 24px;
                  line-height: 32px;
                }
              }
            }
          }
          &.leftSide {
            .balanceDetails {
              .price,
              .text {
                font-size: 18px;
              }
              .inputGroup {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .sectionPart {
      .price {
        span {
          &:first-child {
            font-size: 14px;
          }
        }
      }
      .calculatePart {
        font-size: 14px;
      }
      .rightSection {
        .writersOptions {
          .options {
            .text1 {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
      .formGroup {
        .deadlineLabel {
          font-size: 12px;
        }
      }
    }
  }
}
@include large_device {
  .order {
    .sectionPart {
      &.rightSide {
        top: 70px;
      }
    }
    .orderForm {
      &.orderPage {
        padding-top: 15px;
      }
    }
    .navbarTogglerIcon {
      background-repeat: no-repeat;
      background-size: cover;
      width: 30px;
      height: 20px;
      top: 20px;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      &.close {
        top: 30px;
        right: 15px;
        position: absolute;
        span {
          &:first-child {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            top: 0;
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
          }
        }
      }
      span {
        position: absolute;
        width: 30px;
        height: 2px;
        background: $white-color;
        top: 0;
        right: 0;
        &:nth-child(2) {
          top: 8px;
        }
        &:nth-child(3) {
          top: 16px;
        }
      }
    }
    .sidebarOverlay {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 6;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }
    .navbarToggler {
      position: fixed;
      top: 20px;
      right: 10px;
      z-index: 5;
      width: 30px;
      height: 20px;
      padding: 0;
      @include mobile_device {
        width: 20px;
      }
      &.removeBanner {
        top: 20px;
      }
    }
    .orderLogin {
      .tabTitles {
        padding: 10px;
        background: $theme-color;
        text-align: left;
        color: $white-color;
        font-weight: bold;
        font-size: 16px;
        line-height: 25px;
        position: fixed;
        left: 0;
        right: 0;
        z-index: 2;
        top: 60px;
        svg {
          position: absolute;
          left: 10px;
        }
      }
      .sidebar {
        .profile {
          .name {
            font-size: 16px;
          }
        }
      }
      .checkOrderDetails {
        .tabTitle {
          padding-bottom: 15px;
          margin-bottom: 15px;
        }
        .leftSide {
          .orderFileDetails {
            .files {
              .fileDetails {
                .details {
                  .name {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
      .orderingDetails {
        width: 100%;
        &[class*="myOrdersM"] {
          margin-top: 45px;
        }
        &.width {
          height: calc(100vh - 60px);
        }
        .ordersTab {
          margin: 0;
          .orderTab {
            [class*="tab-content"] {
              padding: 16px;
              .tab-pane {
                .searchOrderWrapper {
                  > .themeBtn {
                    padding: 6px 15px;
                    margin-left: 10px;
                    border: 1px solid $white-color;
                  }

                  .searchBlock {
                    position: relative;
                    top: 0;
                    right: 0;
                  }
                }
                .searchBlock {
                  top: 65px;
                  right: 10px;
                  position: fixed;
                  z-index: 5;
                  &::before {
                    content: none;
                  }
                  .btn {
                    border-radius: 0 6px 6px 0 !important;
                    width: auto;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    padding: 0 10px;
                    border: 1px solid #fff;
                  }
                  .formControl {
                    padding: 2px 15px;
                    border-radius: 6px;
                  }
                }
              }
              .orderDetail {
                background: #ffffff;
                border-radius: $border-radius;
                padding: 16px;
                &:not(:last-child) {
                  margin-bottom: 1rem;
                }
                .flex-direction-column {
                  flex-direction: column;
                  .orderTitle {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    display: block;
                    color: #151515;
                  }
                }
                .deadline {
                  border-top: 1px solid $body-color;
                  border-bottom: 1px solid $body-color;
                  margin: 8px 0;
                  padding: 10px 0;
                  .day {
                    margin-left: 8px;
                    font-size: 14px;
                    line-height: 24px;
                    color: #151515;
                  }
                  .span {
                    border-radius: 4px;
                    padding: 4px 8px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 22px;
                    &.time {
                      background: #fff4e9;
                      color: #ff6b00;
                    }
                    &.waiting {
                      background: #ffe5e5;
                      color: #e53030;
                    }
                    &.complete {
                      background: #ebfbf1;
                      color: #07c451;
                    }
                  }
                }
                .totalPrice {
                  .price {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 32px;
                  }
                  .status {
                    border: 1px solid;
                    padding: 2px 8px;
                    line-height: 26px;
                    border-radius: $border-radius;
                    width: 90px;
                    text-align: center;
                    &.paid {
                      color: #07c451;
                    }
                    &.pay {
                      background: #e53030;
                      color: $white-color;
                    }
                  }
                }
                .text {
                  font-size: 12px;
                  line-height: 22px;
                  color: #5f6368;
                }
              }
            }
            .nav {
              display: flex;
              flex-wrap: nowrap;
              position: sticky;
              top: 0;
              background: #eef1f8;
              .navItem {
                flex: 1;
                width: auto;
                text-align: center;
                [class*="orderTab"] {
                  padding: 7px 0px;
                  font-size: 14px;
                  border-radius: 0;
                  line-height: 25px;
                }
              }
            }
          }
        }
        .draftOrderTab {
          [class*="tab-content"] {
            padding-bottom: 0;
          }
          .navTabs {
            .navItem {
              flex: 50%;
              margin-right: 0;
            }
          }
        }
        .checkboxLabel {
          label {
            &:before {
              width: 26px;
              height: 26px;
            }
          }
          input[type="checkbox"] {
            &:checked {
              & + label {
                &:after {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }
        [class*="tab-content"] {
          &.orderPaper {
            padding-bottom: 100px;
          }
          .sectionPart {
            &.rightSide {
              position: inherit;
            }
          }
        }
        .topLink {
          .orderTotalPrice {
            position: absolute;
            top: 30px;
            right: 35px;
            text-align: center;
            z-index: 1;
            .totalPrice {
              color: $white-color;
              font-weight: 500;
              font-size: 12px;
              line-height: 24px;
            }
            .price {
              color: #07c451;
              font-weight: bold;
              font-size: 14px;
              line-height: normal;
              display: block;
            }
          }
          .navTabs {
            flex-wrap: wrap;
            .navItem {
              [class*="orderTab"] {
                .details {
                  margin-left: 10px;
                  width: calc(100% - 50px);
                }
              }
            }
          }
        }
      }
    }
    .orderMobileMenu {
      padding: 12px 15px;
      height: 60px;
      .navbar {
        background: $theme-color;
        position: fixed;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 5;
        top: 0;
        height: 60px;
        .navbarBrand {
          color: $white-color;
        }
        .container {
          & > .btn {
            margin-right: 30px;
          }
        }
      }
      .navbar-collapse {
        position: fixed;
        background: transparent;
        width: 260px;
        left: -260px;
        top: 0;
        bottom: 0;
        z-index: 99;
        // display: block !important;
        opacity: 0;
        visibility: hidden;
        height: auto !important;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        &.show {
          opacity: 1;
          visibility: visible;
          width: 260px !important;
          left: 0;
          overflow: auto;
        }
      }
      .bottomPart {
        padding: 0;
        margin-right: 40px;
        margin-left: auto;
        .price {
          margin-left: 8px;
        }
      }
    }
  }
  .headerSidebarOpen {
    overflow: hidden;
    .order {
      .navbarToggler {
        .navbarTogglerIcon {
          &.open {
            display: none;
          }
          &.close {
            display: block;
          }
        }
      }
    }
  }
}
@media screen and (max-height: 800px) {
  .order {
    .orderLogin {
      .sidebar {
        .profile {
          padding: 20px;
        }
        .menuLinks {
          ul {
            li {
              margin-bottom: 10px;
              a {
                img {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@include medium_device {
  .orderMobileMenu {
    .navbar {
      .navbarBrand {
        img {
          height: 50px;
        }
      }
    }
  }
  .order {
    .sectionPart {
      padding: 15px;
      .formGroup {
        .selectInput__menu {
          .selectInput__menu-list {
            .selectInput__option {
              font-size: 13px;
              padding: 8px 10px;
            }
          }
        }
        p {
          margin-bottom: 5px;
          text-align: left;
        }
      }
      &.leftSide {
        .p-0 {
          padding-left: 15px !important;
        }
        .calculatePart.p-0 {
          padding: 0 !important;
        }
        .list {
          column-gap: 10px;
        }
      }
      &.rightSide {
        margin-top: 15px;
      }
    }
    .orderForm {
      &.orderPage {
        padding-bottom: 120px;
        & ~ .orderServices {
          padding: 0;
          .service {
            .name {
              font-size: 18px;
            }
          }
        }
      }
    }
    .orderLogin {
      .orderingDetails {
        .orderTab {
          .navTabs {
            .navItem {
              width: 130px;
              text-align: center;
              font-size: 16px;
              display: inline-block;
            }
          }
        }
        & > [class*="tab-content"] {
          .orderSummaryDiv {
            .orderingDiv {
              & > img {
                display: none;
              }
            }
          }
          .draftOrderTab {
            margin-top: 20px;
            .nav {
              overflow-x: overlay;
              white-space: nowrap;
              overflow-y: hidden;
              padding-bottom: 10px;
              display: block;
              &::-webkit-scrollbar {
                width: 0;
              }
              .navItem {
                margin-right: 0;
                width: 180px;
                display: inline-block;
              }
            }
          }
          .sectionPart {
            padding: 15px;
            border-radius: 10px;
            &.leftSide {
              max-height: inherit;
              padding: 20px;
              .stepButtons {
                margin-top: 2rem !important;
              }
              .border-leftSide {
                border: none;
              }
              .paymentOpt {
                .top {
                  margin: 20px 0;
                }
                .totalPrice {
                  padding: 10px;
                  .label,
                  .price {
                    font-size: 16px;
                  }
                }
              }
              &.bundleSection {
                .bundleWrapper {
                  .bundle {
                    width: 100%;
                  }
                }
              }
            }
            &.rightSide {
              margin-top: 15px;
            }
          }
        }
        .topLink {
          padding: 16px;
          .navTabs {
            .navItem {
              width: 100%;
              flex: auto;
              // .nav-link {
              [class*="orderTab"] {
                display: none;
                &.active {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
@include mobile_device {
  .navbarBrand {
    font-size: 22px;
  }
  .order {
    .header {
      &::after {
        height: 20px;
        bottom: -20px;
      }
    }
    .sectionPart {
      &.leftSide {
        .buttons {
          margin-top: 20px;
        }
      }
      .formGroup {
        .formControl {
          padding: 6px 10px;
        }
      }
    }
    .orderLogin {
      .checkOrderDetails {
        .tabTitle {
          .fileInput {
            .fileInputInput {
              width: 90px;
              padding: 3px 12px;
              font-size: 14px;
              &::before {
                left: 10px;
              }
              &::after {
                left: 30px;
              }
            }
          }
          .title {
            font-size: 18px;
          }
          button {
            padding: 2px 8px;
            font-size: 14px;
          }
        }
      }
      .fileDetails {
        display: block;
        padding: 10px;
        .view {
          margin-right: 0;
        }
        .details {
          margin-right: 0;
          .text {
            font-size: 11px;
          }
          .name {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
      .orderingDetails {
        .navTabs {
          .navItem {
            [class*="orderTab"] {
              font-size: 16px;
              padding: 8px 12px;
            }
          }
        }
        & > [class*="tab-content"] {
          .sectionPart {
            &.leftSide {
              .securePay {
                font-size: 16px;
                .desc {
                  font-size: 13px;
                  line-height: 20px;
                }
              }
              .options {
                .ways {
                  .details {
                    .label {
                      font-size: 14px;
                    }
                    .desc {
                      font-size: 13px;
                      line-height: 20px;
                    }
                  }
                }
              }
            }
          }
        }
        .tabTitle {
          padding-bottom: 10px;
          margin-bottom: 10px;
          button {
            padding: 4px 18px;
          }
        }
        .orderDetailsBtn {
          button {
            padding: 4px 8px;
            font-size: 14px;
          }
        }
        .sectionPart {
          .sectionTitle {
            font-size: 18px;
            padding-bottom: 10px;
            margin-bottom: 10px;
          }
          .formGroup {
            textarea {
              padding: 10px;
            }
            .dropzoneInput {
              padding: 10px;
            }
          }
          .calculatePart {
            padding: 8px 0;
            .price {
              span {
                &:first-child {
                  font-size: 14px;
                  line-height: 24px;
                }
              }
            }
          }
          .couponCode {
            padding: 15px 0;
          }
          &.leftSide {
            .options {
              .ways {
                padding: 10px;
                & > img {
                  width: 40px;
                  height: 40px;
                }
                .details {
                  margin-left: 10px;
                }
              }
            }
            .btn {
              padding: 11px 12px;
            }
            .paymentOpt {
              .top {
                padding-bottom: 10px;
                margin-bottom: 10px;
              }
            }
            .bottompart {
              .options {
                margin-top: 0;
              }
            }
            .balanceDetails {
              .inputGroup {
                width: 100%;
                margin-top: 10px;
              }
            }
            .paymentCard {
              padding: 20px 0;
              .inputGroup {
                padding-left: 0;
                margin: 10px 0 0;
              }
            }
          }
        }
        .ordersTab {
          .orderTab {
            [class*="tab-content"] {
              .tab-pane {
                .searchOrderWrapper {
                  > .themeBtn {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .navbarTogglerIcon {
      width: 20px;

      span {
        width: 20px;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .order {
    .orderLogin {
      .orderingDetails {
        .sectionPart {
          &.leftSide {
            .stepButtons {
              margin-top: 1rem !important;
              .btn {
                padding: 8px 5px;
              }
            }
            .list {
              .numberOfPages {
                & + .btn-check {
                  & + label {
                    margin-top: 10px;
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }
        .checkboxLabel {
          label {
            font-size: 14px;
          }
        }
        [class*="tab-content"] {
          &.orderInvoice {
            .orderSummaryDiv {
              .orderingDiv {
                .btn {
                  width: 100%;
                }
              }
            }
          }
        }
        .ordersTab {
          .orderTab {
            [class*="tab-content"] {
              .tab-pane {
                .searchOrderWrapper {
                  > .themeBtn {
                    margin-left: 5px;
                    padding: 6px 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .bottompart,
    .sectionPart {
      .btn {
        width: 100%;
        .ms-2 {
          margin-left: 5px !important;
        }
      }
    }
    .sectionPart {
      .editBtn {
        width: auto;
      }
      .couponCode {
        .btn {
          width: auto;
        }
      }
      .calculatePart {
        font-size: 14px;
      }
      &.leftSide {
        .paymentOpt {
          .cardDetails {
            .bottom {
              flex-wrap: wrap;
            }
          }
        }
        .list {
          flex-wrap: wrap;
        }
      }
      .formGroup {
        .numberOfPages {
          width: 100%;
          margin-bottom: 10px;
        }
        .btn-group {
          .counter,
          .count {
            width: 100px;
          }
          & > * {
            flex: auto;
            width: 100%;
          }
        }
      }
    }
    .bottomPart {
      span {
        font-size: 15px;
      }
    }
  }
}
